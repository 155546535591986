@import '../../styles/customMediaQueries.css';

.root {

  flex-shrink: 0;
  padding: 0;
  height: 100%;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    padding: 0 0.5rem;
  }

  @media (--viewportLarge) {
    padding: 0 1rem;
  }
}
.wrapper {
  display: flex;
  align-items: center;
  color: var(--colorGrey700);
  font-size: 1.5rem;
  letter-spacing: 2px;
  font-family: var(--fontFamilyQuicksand);
  & span {
    font-weight: 600;
  }
}
.vertical {
  flex-direction: column;
}
